import React, {useEffect, useRef, useState} from 'react';
import './Intro.scss';
import ComponentSlider from '../../common/components/slider/ComponentSlider';
import ButtonComponent from '../../common/components/button/ButtonComponent';
import ButtonComponentV2 from '../../common/components/button/ButtonComponent_v2';
import SubHeaderComponent from '../../common/components/SubHeaderComponent/SubHeaderComponent';
import FundCardComponent from '../../common/components/fundCard/FundCardComponent';
import LicenseCertificationComponent from '../../common/components/LicenseCertificationComponent/LicenseCertificationComponent';
import JoinOurCommunityCardComponent from '../../common/components/joinOurCommunityCard/JoinOurCommunityCardComponent';
import ContactUsCardComponent from '../../common/components/contactUsCard/ContactUsCardComponent';
import HowToPlayCardComponent from '../../common/components/howToPlayCard/HowToPlayCardComponent';
import PaymentSupportedCardComponent from '../../common/components/paymentSupportedCard/PaymentSupportedCardComponent';
import scssVariales from '../../resources/theme/Common.scss';
import {useTranslation} from 'react-i18next';
import {
  GetIntroBanner,
  GetCommon,
  GetHome,
  GetIntro,
} from '../../resources/image/Image';
import {ShowLoginDialog} from '../../common/utils/helper';

import {useNav as useNavigate} from '../../common/utils/helper';
import LoginComponent from '../../common/components/LoginComponent/LoginComponent';
import {useSelector} from 'react-redux';
import CustomScrollBar from '../../common/components/CustomScrollBar/CustomScrollBar';
import GameCardComponent from '../home/pageComponent/GameCardComponent';
import api from '../../common/api';
import {isEqual} from 'lodash';
import {getProviderObject} from '../../common/utils/gameListHelper';
import {useModal} from '../../common/context/ModalContext';
import {clientInfo} from '../../resources';

import ExpandCollapseComponent from '../../common/components/ExpandCollapseComponent/ExpandCollapseComponent';
import IntroDownloadAppComponent from '../../common/components/IntroDownloadAppComponent/IntroDownloadAppComponent';
import IntroAllGamesComponent from '../../common/components/IntroPageComponent/IntroAllGamesComponent';
import IntroPromotionComponent from '../../common/components/IntroPageComponent/IntroPromotionComponent';
import IntroAffiliateComponent from '../../common/components/IntroPageComponent/IntroAffiliateComponent';
import IntroClubComponent from '../../common/components/IntroPageComponent/IntroClubComponent';
import {CheckLocalFortuneWheel} from '../../common/utils/FWHelper';
import Home from '../home/Home';

function Intro() {
  const useModalContext = useModal();
  const gameList1Provider = 'pg';
  const gameList1Option = 'hot';
  const gameList2Provider = null;
  const gameList2Option = 'feature';
  const gameList3Provider = null;
  const gameList3Option = 'new';
  const gameCategory = 'slot';
  const {CMSelectedProvider} = useSelector((state) => state.provider);
  const {isLogin} = useSelector((state) => state.user);
  const [gameListing, setGameListing] = useState([]);
  const [pgHotGames, setPgHotGames] = useState([]);
  const [hotGames, setHotGames] = useState([]);
  const [newGames, setNewGames] = useState([]);
  const [loadingState, setLoadingState] = useState(true);
  const {t: trans} = useTranslation();
  const navigate = useNavigate();
  const {CMCategoryGame} = useSelector((state) => state.category);
  const IntroBanner = GetIntroBanner();
  const introImages = [
    IntroBanner.introBanner1,
    IntroBanner.introBanner2,
    IntroBanner.introBanner3,
    IntroBanner.introBanner4,
  ];
  const Common = GetCommon();
  const IntroImg = GetIntro();

  useEffect(() => {
    // getGameList();
    getFortuneWheel();
  }, []);

  useEffect(() => {
    UpdatePgHotGames(gameListing);
    UpdateHotGames(gameListing);
    UpdateNewGames(gameListing);
  }, [gameListing, CMSelectedProvider]);

  function UpdatePgHotGames(allGames) {
    const fil = FilterGameList(allGames, gameList1Provider, gameList1Option);
    setPgHotGames(fil);
  }

  function UpdateHotGames(allGames) {
    const fil = FilterGameList(allGames, gameList2Provider, gameList2Option);
    setHotGames(fil);
  }

  function UpdateNewGames(allGames) {
    const fil = FilterGameList(allGames, gameList3Provider, gameList3Option);
    setNewGames(fil);
  }

  function FilterGameList(allGames, providerCode, optionType) {
    if (CMSelectedProvider.length === 0) return [];

    //filter provider and option
    const skipProviderFilter = providerCode == null ? true : false;
    const skipOptionFilter = optionType == null ? true : false;

    let fil = allGames;

    fil = fil.filter((item) => {
      let isNotMatch = false;
      if (!skipProviderFilter) {
        if (item.platform !== providerCode && item.provider !== providerCode) {
          isNotMatch = true;
        }
      }

      if (!skipOptionFilter && !isNotMatch) {
        if (!item.option.includes(optionType)) {
          isNotMatch = true;
        }
      }

      //only show slot
      if (!isNotMatch) {
        if (item.gametype.toLowerCase() !== gameCategory.toLowerCase()) {
          isNotMatch = true;
        }
      }

      //filter out unneccessary provider
      if (!isNotMatch) {
        if (
          item.provider.includes('glive') ||
          item.provider.includes('taokaebet')
        ) {
          isNotMatch = true;
        }
      }

      return !isNotMatch;
    });

    return fil;
  }

  function getFortuneWheel() {
    if (!isLogin) {
      return;
    }
    const flag = CheckLocalFortuneWheel();

    if (!flag) {
      api.PromoCheckFortuneWheel({});
    }
  }

  function getGameList() {
    const frmLocal = JSON.parse(localStorage.getItem('gList'));
    if (frmLocal) {
      setGameListing(frmLocal);
      setLoadingState(false);
    }

    api.GameList({
      success: (res) => {
        setLoadingState(false);
        if (res.data) {
          const newGameList = [...res.data];
          if (!isEqual(frmLocal, newGameList)) {
            localStorage.setItem('gList', JSON.stringify(newGameList));
            setGameListing(newGameList);
          }
        } else {
          setGameListing([]);
        }
      },
      error: () => {
        setLoadingState(false);
        setGameListing([]);
      },
    });
  }

  function authorized(extraState) {
    if (!isLogin) {
      ShowLoginDialog(useModalContext, {
        from: '/game',
        extraState: {...extraState},
      });
      return false;
    }
    return true;
  }

  function PlayGame(object) {
    let playGameState = {playGameState: [object]};
    if (!authorized(playGameState)) {
      return;
    }
    let item = object;

    api.PlayGame({
      provider: item.provider,
      gameid: item.gameid,
      gametype: item.gametype,
      platform: item.platform,
      success: (res) => {
        const {url, game} = res.data;

        navigate('/playgame', {
          state: {
            gamename: item.gamename,
            url: url,
            game: game,
          },
        });
      },
      error: (res) => {
        return;
      },
    });
  }

  function ViewAllGames(gameListProvider) {
    let providerObject = CMSelectedProvider.find(
      (item) => item.code === gameListProvider,
    );
    providerObject = {
      config: providerObject,
      ...getProviderObject(gameListProvider, providerObject),
    };

    // sessionStorage.setItem(
    //   dashboardInfoKey,
    //   JSON.stringify({
    //     isProviderScreen: false,
    //     selectedProvider: providerObject,
    //   }),
    // );
    navigate('/game', {state: {selectedProvider: providerObject}});
  }

  function navigateToGame(category) {
    let categoryObject = CMCategoryGame.find(
      (item) => item.categoryen === category,
    );
    console.log(categoryObject);
    navigate('/', {state: {selectedCategory: categoryObject}});
  }

  return (
    <div className="intro-main-container">
      <div>
        <div className="intro-slider-container intro-width-container">
          <ComponentSlider
            autoLoopInterval={3000}
            showInnerIndicator={true}
            showBottomFaded={false}>
            {introImages.map((image, index) => {
              return (
                <div key={index} style={{position: 'relative', width: '100%'}}>
                  <img
                    key={index}
                    src={image}
                    alt={`Slide${index}`}
                    draggable="false"
                  />
                </div>
              );
            })}
          </ComponentSlider>
        </div>
        {isLogin ? (
          <div className="intro-fund-container container-padding">
            <FundCardComponent />
          </div>
        ) : (
          <div className="intro-login-container container-padding">
            <LoginComponent />
          </div>
        )}
      </div>
      <div className="intro-content-container container-padding intro-width-container">
        <div className="intro-separator" />
        {/* {loadingState || CMSelectedProvider.length <= 0 ? null : (
        <div className="intro-section-container">
          <div className="hotGames-subheader-container">
            <SubHeaderComponent
              subHeaderImgAlt={'fire-icon'}
              subHeaderImgSrc={Common.fire}
              subHeaderText={`${trans('intropage.hotGames')}`}
              btnClassName={'allGames-list-btn'}
              btnText={`${trans('intropage.viewAllBtn')}`}
              onClick={() => {
                ViewAllGames(gameList1Provider);
              }}></SubHeaderComponent>
          </div>
          <CustomScrollBar>
            <div className="intro-game-listing-container">
              {pgHotGames.map((game, index) => {
                return (
                  <div
                    className="intro-game-item-container"
                    key={game.gameid}
                    onClick={() => {
                      PlayGame(game);
                    }}>
                    <GameCardComponent item={game}></GameCardComponent>
                  </div>
                );
              })}
            </div>
          </CustomScrollBar>
        </div>
      )}

      {loadingState || CMSelectedProvider.length <= 0 ? null : (
        <div className="intro-section-container">
          <div className="featuredGames-subheader-container">
            <SubHeaderComponent
              subHeaderImgAlt={'fire-icon'}
              subHeaderImgSrc={Common.fire}
              subHeaderText={`${trans('intropage.featuredGames')}`}
              btnClassName={'allGames-list-btn'}
              btnText={`${trans('intropage.viewAllBtn')}`}
              onClick={() => {
                ViewAllGames(gameList2Option);
              }}></SubHeaderComponent>
          </div>
          <CustomScrollBar>
            <div className="intro-game-listing-container">
              {hotGames.map((game, index) => {
                return (
                  <GameCardComponent
                    key={game.gameid}
                    item={game}
                    className={'intro-game-item-container'}
                    onClick={() => {
                      PlayGame(game);
                    }}></GameCardComponent>
                );
              })}
            </div>
          </CustomScrollBar>
        </div>
      )}

      {loadingState || CMSelectedProvider.length <= 0 ? null : (
        <div className="intro-section-container">
          <div className="newGames-subheader-container">
            <SubHeaderComponent
              subHeaderImgAlt={'star-icon'}
              subHeaderImgSrc={Common.star}
              subHeaderText={`${trans('intropage.newGames')}`}
              btnClassName={'allGames-list-btn'}
              btnText={`${trans('intropage.viewAllBtn')}`}
              onClick={() => {
                ViewAllGames(gameList3Option);
              }}></SubHeaderComponent>
          </div>
          <CustomScrollBar>
            <div className="intro-game-listing-container">
              {newGames.map((game, index) => {
                return (
                  <GameCardComponent
                    key={game.gameid}
                    item={game}
                    className={'intro-game-item-container'}
                    onClick={() => {
                      PlayGame(game);
                    }}></GameCardComponent>
                );
              })}
            </div>
          </CustomScrollBar>
        </div>
      )} */}

        {/* <div className="intro-grid-container">
          <IntroAllGamesComponent />
          <IntroPromotionComponent />
          <IntroAffiliateComponent />
          <IntroClubComponent />
        </div> */}
        <div className="intro-gamelist-container">
          <Home isPage={false}></Home>
        </div>
      </div>

      <div className="intro-bottom-container-bg container-padding">
        <div className="intro-bottom-container">
          <div className="brand-about-container">
            <div style={{height: '28px'}}> </div>
            <div className="brand-about-logo">
              <img
                className="brand-about-logo-icon"
                src={IntroImg.brandLogo}
                alt="brand-logo"
              />
              {clientInfo.headerAppName ? (
                <div>{`${clientInfo.headerAppName}`}</div>
              ) : null}
            </div>
            <div style={{height: '17px'}}> </div>
            <div className="brand-about-info">{`${trans(
              'intropage.aboutUsInfo',
            )}`}</div>
            <div style={{height: '32px'}}> </div>
          </div>

          <div className="intro-bottom-iconinfo-container">
            <LicenseCertificationComponent
              title={`${trans('intropage.licensesCertification')}`}
              text={`${trans('intropage.licensesCertificationInfo')}`}
            />
            <JoinOurCommunityCardComponent
              title={`${trans('intropage.joinOurCommunity')}`}
            />
            <PaymentSupportedCardComponent
              title={`${trans('intropage.supportedPayment')}`}
            />
            {/* <ContactUsCardComponent /> */}
          </div>

          <div className="sitemap-container">
            <div>
              <ExpandCollapseComponent
                expandedHeaderComponent={
                  <div className="sitemap-subheader-row">
                    <div className="sitemap-subheader-title">{`${trans(
                      'intropage.wallet',
                    )}`}</div>
                    <img
                      className="sitemap-subheader-collapse-icon"
                      src={Common.collapseArrow}
                      alt={`sitemap-subheader-collapse-icon`}></img>
                  </div>
                }
                collapsedHeaderComponent={
                  <div className="sitemap-subheader-row">
                    <div className="sitemap-subheader-title">{`${trans(
                      'intropage.wallet',
                    )}`}</div>
                    <img
                      className="sitemap-subheader-expand-icon"
                      src={Common.expandArrow}
                      alt={`sitemap-subheader-expand-icon`}></img>
                  </div>
                }>
                <ButtonComponentV2
                  btnClassName={'sitemap-content-item'}
                  text={`${trans('intropage.wallet')}`}
                  onClick={() => {
                    navigate('/fund');
                  }}
                />
                {/* <ButtonComponentV2
                btnClassName={'sitemap-content-item'}
                text={`${trans('intropage.deposit')}`}
                onClick={''}
              />
              <ButtonComponentV2
                btnClassName={'sitemap-content-item'}
                text={`${trans('intropage.withdrawal')}`}
                onClick={''}
              /> */}
                <ButtonComponentV2
                  btnClassName={'sitemap-content-item'}
                  text={`${trans('intropage.transcationRecords')}`}
                  onClick={() => {
                    navigate('/transaction');
                  }}
                />
                {/* <ButtonComponentV2
                btnClassName={'sitemap-content-item'}
                text={`${trans('intropage.gameLog')}`}
                onClick={''}
              /> */}
                <ButtonComponentV2
                  btnClassName={'sitemap-content-item'}
                  text={`${trans('intropage.myProfile')}`}
                  onClick={() => {
                    navigate('/profile');
                  }}
                />
                <ButtonComponentV2
                  btnClassName={'sitemap-content-item'}
                  text={`${trans('intropage.myBank')}`}
                  onClick={() => {
                    navigate('/bankList');
                  }}
                />
              </ExpandCollapseComponent>
            </div>

            <div>
              <ExpandCollapseComponent
                expandedHeaderComponent={
                  <div className="sitemap-subheader-row">
                    <div className="sitemap-subheader-title">{`${trans(
                      'intropage.promotion',
                    )}`}</div>
                    <img
                      className="sitemap-subheader-collapse-icon"
                      src={Common.collapseArrow}
                      alt={`sitemap-subheader-collapse-icon`}></img>
                  </div>
                }
                collapsedHeaderComponent={
                  <div className="sitemap-subheader-row">
                    <div className="sitemap-subheader-title">{`${trans(
                      'intropage.promotion',
                    )}`}</div>
                    <img
                      className="sitemap-subheader-expand-icon"
                      src={Common.expandArrow}
                      alt={`sitemap-subheader-expand-icon`}></img>
                  </div>
                }>
                <div>
                  <ButtonComponentV2
                    btnClassName={'sitemap-content-item'}
                    text={`${trans('intropage.promotion')}`}
                    onClick={() => {
                      navigate('/mission');
                    }}
                  />
                  {/* <ButtonComponentV2
                  btnClassName={'sitemap-content-item'}
                  text={`${trans('intropage.bonus')}`}
                  onClick={''}
                />
                <ButtonComponentV2
                  btnClassName={'sitemap-content-item'}
                  text={`${trans('intropage.mission')}`}
                  onClick={''}
                />
                <ButtonComponentV2
                  btnClassName={'sitemap-content-item'}
                  text={`${trans('intropage.rebate')}`}
                  onClick={''}
                /> */}
                </div>
              </ExpandCollapseComponent>
            </div>

            <div>
              <ExpandCollapseComponent
                expandedHeaderComponent={
                  <div className="sitemap-subheader-row">
                    <div className="sitemap-subheader-title">{`${trans(
                      'intropage.vip',
                    )}`}</div>
                    <img
                      className="sitemap-subheader-collapse-icon"
                      src={Common.collapseArrow}
                      alt={`sitemap-subheader-collapse-icon`}></img>
                  </div>
                }
                collapsedHeaderComponent={
                  <div className="sitemap-subheader-row">
                    <div className="sitemap-subheader-title">{`${trans(
                      'intropage.vip',
                    )}`}</div>
                    <img
                      className="sitemap-subheader-expand-icon"
                      src={Common.expandArrow}
                      alt={`sitemap-subheader-expand-icon`}></img>
                  </div>
                }>
                <div>
                  <ButtonComponentV2
                    btnClassName={'sitemap-content-item'}
                    text={`${trans('intropage.vipClub')}`}
                    onClick={() => {
                      navigate('/club');
                    }}
                  />
                  {/* <ButtonComponentV2
                  btnClassName={'sitemap-content-item'}
                  text={`${trans('intropage.vipBenefits')}`}
                  onClick={''}
                />
                <ButtonComponentV2
                  btnClassName={'sitemap-content-item'}
                  text={`${trans('intropage.vipTier')}`}
                  onClick={''}
                />
                <ButtonComponentV2
                  btnClassName={'sitemap-content-item'}
                  text={`${trans('intropage.vipConcierge')}`}
                  onClick={''}
                /> */}
                </div>
              </ExpandCollapseComponent>
            </div>

            <div>
              <ExpandCollapseComponent
                expandedHeaderComponent={
                  <div className="sitemap-subheader-row">
                    <div className="sitemap-subheader-title">{`${trans(
                      'intropage.games',
                    )}`}</div>
                    <img
                      className="sitemap-subheader-collapse-icon"
                      src={Common.collapseArrow}
                      alt={`sitemap-subheader-collapse-icon`}></img>
                  </div>
                }
                collapsedHeaderComponent={
                  <div className="sitemap-subheader-row">
                    <div className="sitemap-subheader-title">{`${trans(
                      'intropage.games',
                    )}`}</div>
                    <img
                      className="sitemap-subheader-expand-icon"
                      src={Common.expandArrow}
                      alt={`sitemap-subheader-expand-icon`}></img>
                  </div>
                }>
                <div>
                  {/* <ButtonComponentV2
                  btnClassName={'sitemap-content-item'}
                  text={`${trans('intropage.gamesID')}`}
                  onClick={''}
                /> */}
                  <ButtonComponentV2
                    btnClassName={'sitemap-content-item'}
                    text={`${trans('intropage.slots')}`}
                    onClick={() => {
                      navigateToGame('Slot');
                    }}
                  />
                  <ButtonComponentV2
                    btnClassName={'sitemap-content-item'}
                    text={`${trans('intropage.casino')}`}
                    onClick={() => {
                      navigateToGame('Live');
                    }}
                  />
                  <ButtonComponentV2
                    btnClassName={'sitemap-content-item'}
                    text={`${trans('intropage.fish')}`}
                    onClick={() => {
                      navigateToGame('Fish');
                    }}
                  />
                  <ButtonComponentV2
                    btnClassName={'sitemap-content-item'}
                    text={`${trans('intropage.sport')}`}
                    onClick={() => {
                      navigateToGame('Sports');
                    }}
                  />
                  {/* <ButtonComponentV2
                  btnClassName={'sitemap-content-item'}
                  text={`${trans('intropage.lotto')}`}
                  onClick={''}
                />
                <ButtonComponentV2
                  btnClassName={'sitemap-content-item'}
                  text={`${trans('intropage.appGames')}`}
                  onClick={''}
                /> */}
                </div>
              </ExpandCollapseComponent>
            </div>

            <div>
              <ExpandCollapseComponent
                expandedHeaderComponent={
                  <div className="sitemap-subheader-row">
                    {' '}
                    <div className="sitemap-subheader-title">{`${trans(
                      'general.title.affiliate',
                    )}`}</div>{' '}
                    <img
                      className="sitemap-subheader-collapse-icon"
                      src={Common.collapseArrow}
                      alt={`sitemap-subheader-collapse-icon`}></img>
                  </div>
                }
                collapsedHeaderComponent={
                  <div className="sitemap-subheader-row">
                    {' '}
                    <div className="sitemap-subheader-title">{`${trans(
                      'general.title.affiliate',
                    )}`}</div>{' '}
                    <img
                      className="sitemap-subheader-expand-icon"
                      src={Common.expandArrow}
                      alt={`sitemap-subheader-expand-icon`}></img>
                  </div>
                }>
                <div>
                  <ButtonComponentV2
                    btnClassName={'sitemap-content-item'}
                    text={`${trans('general.title.affiliate')}`}
                    onClick={() => {
                      navigate('/affiliate');
                    }}
                  />
                  <ButtonComponentV2
                    btnClassName={'sitemap-content-item'}
                    text={`${trans('intropage.affiliateReport')}`}
                    onClick={() => {
                      navigate('/comm');
                    }}
                  />
                  {/* <ButtonComponentV2
                  btnClassName={'sitemap-content-item'}
                  text={`Premium Affiliate`}
                  onClick={''}
                /> */}
                </div>
              </ExpandCollapseComponent>
            </div>

            <div>
              <ExpandCollapseComponent
                expandedHeaderComponent={
                  <div className="sitemap-subheader-row">
                    <div className="sitemap-subheader-title">{`${trans(
                      'intropage.aboutUs',
                    )}`}</div>
                    <img
                      className="sitemap-subheader-collapse-icon"
                      src={Common.collapseArrow}
                      alt={`sitemap-subheader-collapse-icon`}></img>
                  </div>
                }
                collapsedHeaderComponent={
                  <div className="sitemap-subheader-row">
                    <div className="sitemap-subheader-title">{`${trans(
                      'intropage.aboutUs',
                    )}`}</div>
                    <img
                      className="sitemap-subheader-expand-icon"
                      src={Common.expandArrow}
                      alt={`sitemap -subheader-expand-icon`}></img>
                  </div>
                }>
                <div className="sitemap-aboutUs-description">
                  <pre className="sitemap-aboutUs-description-pre">{`${clientInfo.seoSignV2}`}</pre>
                </div>
              </ExpandCollapseComponent>
            </div>
          </div>

          <div className="copyright">{`${trans(`intropage.copyright`)}`}</div>
        </div>
      </div>
    </div>
  );
}

export default Intro;
