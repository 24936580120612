import React, {useEffect} from 'react';
// import {useNavigate} from 'react-router-dom';

import './App.scss';
import api from './common/api';
import Root from './common/components/root';
import Navigation from './pages/routes/auth/MainIndex';
import HeaderFooterLayout from './pages/home/HeaderFooterLayout';
import {useNav} from './common/utils/helper';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {setNavigateHelper} from './common/utils/navigateHelper';
import {
  LOGIN_ACTION_KEY_SCRIPT,
  REGISTERED_ACTION_KEY_SCRIPT,
} from './common/utils/pageSettingHelper';

export default function App(props) {
  // Remove console log in production
  if (process.env.NODE_ENV === 'production')
    console.log = function no_console() {};

  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  let isMobileDevice = regexp.test(details);

  // stop right click
  if (!isMobileDevice) {
    document.addEventListener('contextmenu', function (e) {
      e.preventDefault();
    });
  }

  const theme = useSelector((state) => state.styling.theme);
  const {i18n} = useTranslation();
  const navigate = useNav();
  setNavigateHelper(navigate);

  //iframe created by ourself need to put classname brand-iframe-container in order to support change language and theme
  function sendMessageToIframe(data) {
    const iframes = document.getElementsByClassName('brand-iframe-container');
    for (let i = 0; i < iframes.length; i++) {
      const iframe = iframes[i];
      iframe.contentWindow.postMessage(data, '*');
    }
  }
  useEffect(() => {
    sendMessageToIframe({language: i18n.language});
  }, [i18n.language]);

  useEffect(() => {
    sendMessageToIframe({theme: theme});
  }, [theme]);

  useEffect(() => {
    api.PageSetting({
      name: 'all',
      success: (res) => {
        if (res.data && res.data.length > 0) {
          const data = res.data[0];

          //head
          insertElement(data.HeadScript, (element) => {
            document.head.appendChild(element);
          });

          //body start
          const firstChild = document.body.firstChild;
          insertElement(data.BodyScriptStart, (element) => {
            document.body.insertBefore(element, firstChild);
          });

          //body end
          insertElement(data.BodyScriptEnd, (element) => {
            document.body.appendChild(element);
          });

          //registerSuccess script
          insertScriptFunction(
            REGISTERED_ACTION_KEY_SCRIPT,
            data.RegisterScript ?? '',
          );

          //login script
          insertScriptFunction(LOGIN_ACTION_KEY_SCRIPT, data.LoginScript ?? '');
        }
      },
    });
  }, []);

  function insertScriptFunction(functionName, functionContent) {
    const successRegister = `function ${functionName}() {
      ${functionContent}
    }`;
    // Create a script element
    const scriptElement = document.createElement('script');

    scriptElement.textContent = successRegister;

    // Append the script element to the head
    document.head.appendChild(scriptElement);
  }

  function convertStringToHtml(stringHtml) {
    const htmlElement = document.createElement('div');
    htmlElement.innerHTML = stringHtml;
    return htmlElement;
  }

  function insertElement(stringHtml, insertAction) {
    const htmlElement = convertStringToHtml(stringHtml);

    Array.from(htmlElement.children).forEach((element) => {
      if (element.tagName.toLowerCase() == 'script') {
        const scriptElement = document.createElement('script');
        scriptElement.textContent = element.textContent;
        insertAction(scriptElement);
      } else {
        insertAction(element);
      }
    });
  }

  return (
    <div>
      <Root navigate={navigate}>
        <HeaderFooterLayout>
          <Navigation />
        </HeaderFooterLayout>
      </Root>
    </div>
  );
}
